@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	background-color: black;
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.background-image {
	background-image: url('./assets/bg.jpg');
	background-position: right center;
	background-repeat: no-repeat;
	min-height: 100vh;
}

.App {
	text-align: center;
	max-width: 728px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: 100vh;
	background-color: rgb(30, 41, 59);
}

.App-gallery {
	background-color: rgb(11, 12, 15);
}

.App-landing {
	background-color: rgb(11, 12, 15);
}

main::-webkit-scrollbar {
	width: 0.25rem;
}

main::-webkit-scrollbar-track {
	background: #1e1e24;
}

main::-webkit-scrollbar-thumb {
	background: #abdbe9;
}

.niceScrollbar::-webkit-scrollbar {
	width: 0.25rem;
}

.niceScrollbar::-webkit-scrollbar-track {
	background: #1e1e24;
}

.niceScrollbar::-webkit-scrollbar-thumb {
	background: #abdbe9;
}

.chatbubble {
	max-width: 500px;
	margin-bottom: 12px;
	line-height: 24px;
	padding: 10px 20px;
	border-radius: 12px;
	position: relative;
	color: white;
	text-align: center;
}

.message {
	display: flex;
	align-items: center;
}

.sent {
	flex-direction: row-reverse;
}

.sent p {
	color: white;
	background: #0b93f6;
	align-self: flex-end;
}
.received p {
	background: #e5e5ea;
	color: black;
}

.avatar {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	margin: 2px 5px;
}

/* TURN OFF TOAST CLOSE BUTTON */
.yarl__button {
	display: none;
}

.agent_header {
	background: linear-gradient(to right, rgb(78, 77, 163), rgb(86, 79, 177));
}
